import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { GLGlobal, GLUtil, withRouter } from "gl-commonui";
import { CollectionView } from "wijmo/wijmo";
import { Button, Modal, Row } from "antd-min";
import {
    lazyInject,
    TYPES,
    fmtMsg,
    GSAdminAction,
    DateHelper,
    isUserGlobalHead,
    isInternalUserWithoutAM
} from "../../../util/index";
import { GSAdminLocale } from "@app/locales/localeid";
import { ColumnLink, WijmoGrid, Container, MainTitle } from "@app/components/";
import { PathConfig } from "@app/config/pathconfig";
import {
    IStudentVerificationService,
    StudentListModel,
    StudentListPropsModel,
    IGetStudentListRequestQueryParam,
    StudentSuggestionsModel
} from "../../../service/admin/studentVerification/index";
import { CONSTS, STUDENT_VERIFICATION_PG_SIZE } from "../../../util/consts";
import "./managestudents.less";
import { StudentFieldSelectComponent } from "./components/studentsearchform";
import { IStudentRegistrationService } from "@app/service/school/student-registration";
import { ISchoolClassService } from "@app/service/class";

const { Grid, Column } = WijmoGrid;

interface ManageStudentsPageProps extends RouteComponentProps<any> {}
interface ManageStudentsPageStates {
    studentsList: StudentListModel[];
    keyword: string;
    totalRecords: number;
    visible: boolean;
    loading: boolean;
    accessibleClassIds: string[];
}
@withRouter
export class ManageStudentsPage extends Component<
    ManageStudentsPageProps,
    ManageStudentsPageStates
> {
    @lazyInject(TYPES.IStudentVerificationService)
    service: IStudentVerificationService;
    @lazyInject(TYPES.IStudentRegistrationService)
    studRegService: IStudentRegistrationService;
    @lazyInject(TYPES.ISchoolClassService)
    schoolClassService: ISchoolClassService;
    modalprops;
    constructor(props) {
        super(props);
        this.state = {
            studentsList: [],
            keyword: null,
            totalRecords: 0,
            visible: false,
            loading: true,
            accessibleClassIds: []
        };
        this.onCancel = this.onCancel.bind(this);
        this.onConfirmVerifyAll = this.onConfirmVerifyAll.bind(this);
        this.onVerifyAll = this.onVerifyAll.bind(this);
        this.onSearchClicked = this.onSearchClicked.bind(this);
        this.redirectToMergeOrVerify = this.redirectToMergeOrVerify.bind(this);
    }
    regionId = this.props.match.params.regionId;
    componentDidMount() {
        // get accessible class in case of external user only and account manager
        if (!isInternalUserWithoutAM()) {
            this.schoolClassService.getAccessibleClassIds().then(ids => {
                this.setState({ accessibleClassIds: ids });
                this.getStudents(this.regionId, this.state.keyword);
            });
        } else {
            this.getStudents(this.regionId, this.state.keyword);
        }
    }
    onSearchClicked(keyword) {
        this.setState({ keyword });
        this.getStudents(this.regionId, keyword);
    }
    getStudents(regionId, keyword) {
        const params4UrlQuery: IGetStudentListRequestQueryParam = {
            regionId: regionId,
            keyword: keyword
        };
        this.service.getStudentsList(params4UrlQuery).then(response => {
            this.setState({
                studentsList: response.data,
                totalRecords: response.totalCount,
                loading: false
            });
        });
    }

    onVerifyAll() {
        this.setState({
            visible: true
        });
    }
    onCancel() {
        this.setState({
            visible: false
        });
    }
    onConfirmVerifyAll() {
        this.setState({
            visible: false
        });
        const params: IGetStudentListRequestQueryParam = {
            regionId: this.regionId,
            keyword: this.state.keyword
        };
        this.service.verifyAllStudents(params).then(response => {
            if (response) this.getStudents(this.regionId, this.state.keyword);
        });
    }

    redirectToVerificationScreen(record: StudentListModel) {
        this.props.history.push({
            pathname: GLUtil.pathStringify(PathConfig.VerifyStudents, {
                regionId: this.regionId,
                mergedClassId: record.schoolClassId,
                mergedStudentId: record.studentId,
                potentialMatchId: CONSTS.EmptyGuid
            })
        });
    }

    redirectToMergeOrVerify(record: StudentListModel) {
        if (record.isMatched) {
            this.redirectToVerificationScreen(record);
            return;
        }

        const stdDetailForSuggestion: any = [
            {
                studentId: record.studentId,
                englishName: record.studentEnglishName,
                name: record.studentName,
                parentId: record.studentParentId,
                birthday: record.birthday,
                gender: record.gender
            }
        ];

        this.setState({ loading: true });
        this.studRegService
            .getSuggestions(this.regionId, stdDetailForSuggestion)
            .then(response => {
                let currentRecordSuggestions: any[] = response.matches[0].suggestions.filter(
                    sug => sug.studentId != record.studentId
                );

                if (!isInternalUserWithoutAM()) {
                    // filter user role level suggestions based on his/her accessible region, school, campus, class
                    currentRecordSuggestions = currentRecordSuggestions.filter(
                        s => this.state.accessibleClassIds.includes(s.classId)
                    );
                }

                if (currentRecordSuggestions.length === 0) {
                    this.redirectToVerificationScreen(record);
                } else {
                    this.props.history.push({
                        pathname: GLUtil.pathStringify(
                            PathConfig.MergeStudents,
                            {
                                regionId: this.regionId,
                                mergedClassId: record.schoolClassId,
                                mergedStudentId: record.studentId
                            }
                        )
                    });
                }
                // this.setState({ loading: false });
            })
            .catch(er => {
                this.setState({ loading: false });
            });
    }

    render() {
        const view = new CollectionView(this.state.studentsList);
        this.modalprops = {
            visible: this.state.visible,
            title: fmtMsg(GSAdminLocale.RegionManageStudentsModalTitle),
            onCancel: this.onCancel,
            wrapClassName: "vertical-center-modal",
            width: "450px",
            height: "",
            footer: null
        };
        return (
            <Container fullWidth className="students-container">
                <Modal {...this.modalprops}>
                    <Row>
                        <span>
                            {this.state.totalRecords}
                            {fmtMsg(
                                GSAdminLocale.RegionManageStudentsModalContent
                            )}
                        </span>
                    </Row>
                    <div className="actions">
                        <Button
                            className="ant-btn-primary"
                            onClick={this.onConfirmVerifyAll}
                        >
                            {fmtMsg(
                                GSAdminLocale.RegionManageStudentsModalConfirm
                            )}
                        </Button>
                        <Button className="ant-btn" onClick={this.onCancel}>
                            {fmtMsg(
                                GSAdminLocale.RegionManageStudentsModalCancel
                            )}
                        </Button>
                    </div>
                </Modal>
                <MainTitle
                    plain={fmtMsg({
                        id: GSAdminLocale.RegionManageStudentsTitle
                    })}
                />
                <StudentFieldSelectComponent
                    onSubmit={this.onSearchClicked}
                ></StudentFieldSelectComponent>
                {!isUserGlobalHead() && (
                    <a
                        href="javascript:void(0)"
                        style={{ float: "right" }}
                        onClick={this.onVerifyAll}
                        className={
                            this.state.totalRecords > 0 ? "" : "disableLink"
                        }
                    >
                        {fmtMsg(GSAdminLocale.RegionManageStudentsVerifyAll)}
                    </a>
                )}
                <br />
                <Grid
                    loading={this.state.loading}
                    grouping={true}
                    pagination={true}
                    paginationSize={STUDENT_VERIFICATION_PG_SIZE}
                    itemsSource={view}
                    defaultSort={{
                        column: StudentListPropsModel.registrationDate,
                        ascending: true
                    }}
                >
                    <Column
                        binding={StudentListPropsModel.student}
                        header={fmtMsg({
                            id: GSAdminLocale.RegionManageStudentsName
                        })}
                    />
                    <Column
                        binding={StudentListPropsModel.school}
                        header={fmtMsg({
                            id: GSAdminLocale.RegionManageStudentsSchool
                        })}
                    />
                    <Column
                        binding={StudentListPropsModel.campus}
                        header={fmtMsg({
                            id: GSAdminLocale.RegionManageStudentsCampus
                        })}
                    />
                    <Column
                        binding={StudentListPropsModel.class}
                        header={fmtMsg({
                            id: GSAdminLocale.RegionManageStudentsClass
                        })}
                    />
                    <Column
                        align="left"
                        binding={StudentListPropsModel.unit}
                        header={fmtMsg({
                            id: GSAdminLocale.RegionManageStudentsUnit
                        })}
                    />
                    <Column
                        binding={StudentListPropsModel.registrationDateTime}
                        header={fmtMsg({
                            id: GSAdminLocale.RegionManageStudentsRegDate
                        })}
                        render={(value, record) => {
                            return DateHelper.toLocalStringFromUTC(value);
                        }}
                    />
                    {!isUserGlobalHead() && (
                        <Column
                            allowSorting={false}
                            allowDragging={false}
                            header={fmtMsg({
                                id: GSAdminLocale.RegionManageStudentsVerify
                            })}
                            render={(value, record) => {
                                if (
                                    GLGlobal.isActionValid(
                                        GSAdminAction.VerificationManageStudents
                                    ) &&
                                    this.regionId
                                ) {
                                    return (
                                        <a
                                            href="javascript:void(0)"
                                            onClick={e =>
                                                this.redirectToMergeOrVerify(
                                                    record
                                                )
                                            }
                                        >
                                            {" "}
                                            {fmtMsg({
                                                id:
                                                    GSAdminLocale.RegionManageStudentsVerify
                                            })}
                                        </a>
                                    );
                                } else {
                                    return <></>;
                                }
                            }}
                        />
                    )}
                </Grid>
            </Container>
        );
    }
}

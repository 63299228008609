import * as React from "react";
import { Component } from "react";
import {
    GLForm,
    GLFormComponentProps,
    FormHelper,
    FormItemsLayout,
    GLGlobal
} from "gl-commonui";
import { lazyInject, TYPES } from "../../../../util/index";
import { Row, Form, Button, Input, Col } from "antd-min";
import { GSAdminLocale } from "../../../../locales/localeid";
import {
    IStudentVerificationService,
    StudentFormModelNames
} from "../../../../service/admin/studentVerification/index";

export interface StudentFieldSelectProps {
    onSubmit?: React.MouseEventHandler<any>;
}
@GLForm.create()
export class StudentFieldSelectComponent extends Component<
    StudentFieldSelectProps & GLFormComponentProps
> {
    @lazyInject(TYPES.IStudentVerificationService)
    service: IStudentVerificationService;
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit(values.student);
            }
        });
    }
    render() {
        const { form } = this.props;
        const { renderFormItem } = FormHelper;
        const fmt = GLGlobal.intl.formatMessage;
        return (
            <>
                <Form onSubmit={this.handleSubmit}>
                    <Row gutter={10} type="flex">
                        <Col>
                            <FormItemsLayout
                                colTotal={1}
                                style={{ maxWidth: "unset" }}
                            >
                                {renderFormItem(
                                    form,
                                    GSAdminLocale.Search,
                                    StudentFormModelNames.student,
                                    <Input
                                        placeholder={fmt({
                                            id:
                                                GSAdminLocale.RegionManageStudentsSearch
                                        })}
                                    />
                                )}
                            </FormItemsLayout>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                className="student-search"
                                htmlType="submit"
                            >
                                {fmt({ id: GSAdminLocale.Search })}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    }
}
